import { css } from "@@panda/css";
import { Box, HStack, VStack } from "@@panda/jsx";
import { Button } from "components/atoms/Button";
import { Divider } from "components/atoms/Divider";
import { Link } from "components/atoms/Link";
import { Form } from "components/forms/Form/Form";
import { LoadingBanner } from "components/molecules/LoadingBanner/LoadingBanner";
import { GoogleIcon } from "utility/icons/GoogleIcon";

import { PasswordCredentials, usePasswordForm } from "./usePasswordForm";

export function PasswordForm() {
  const { onSubmit, isSubmitting, ready, googleProvider } = usePasswordForm();

  if (!ready) return <LoadingBanner />;

  return (
    <Form
      display="flex"
      flexDir="column"
      gap="6"
      pt="m"
      onSubmit={onSubmit}
      config={{
        schema: PasswordCredentials,
      }}
    >
      {googleProvider && (
        <Button width="full" as="a" href={googleProvider.link}>
          <HStack gap="0.5rem" justifyContent="center">
            <GoogleIcon
              size="0.875rem"
              className={css({ color: "grey.minecraft" })}
            />
            <p> Log in with Google </p>
          </HStack>
        </Button>
      )}

      <Divider />

      <VStack alignItems="start" w="full" gap="l">
        <VStack alignItems="start" w="full" gap="l" mb="3" mt="-1">
          <Form.TextField
            label="Email"
            name="email"
            placeholder="odin@example.com"
            autoComplete="username"
          />

          <Box w="full">
            <Form.TextField
              label="Password"
              name="password"
              placeholder="••••••••"
              type="password"
              autoComplete="current-password"
            />
          </Box>
        </VStack>

        <VStack alignItems="center" w="full" gap="6">
          <Form.Submit
            mt="0"
            variant="secondary"
            width="full"
            isLoading={isSubmitting}
          >
            Continue with email
          </Form.Submit>
          <Link href="/forgot-password">Forgot password</Link>
        </VStack>
      </VStack>
    </Form>
  );
}
