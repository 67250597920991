import { Account, AccountStatus } from "@internal/rest/generated/schemas";
import { NextRouter } from "next/router";
import { computeNeedsOnboarding } from "screens/onboarding/utils";
import { API_ADDRESS } from "services/config";
import { z } from "zod";

export function getAccountImageUrl(accountID: string) {
  return `${API_ADDRESS}/v3/accounts/${accountID}/image`;
}

export const readyAccountStatuses: Array<string | undefined> = [
  AccountStatus.ready,
];

export function hasPassedFCAChecks(account: Account | undefined) {
  if (!account) return false;

  const intention = account.signup_params.signup_intention;
  const status = account.status;

  if (intention === "founder") return false;
  if (status === "categorisation_required") return false;
  if (status === "sophisticated_investor_declaration_required") return false;

  return true;
}

const Params = z.object({
  returnUrl: z.string().optional(),
});

export async function handleLoginRedirect(
  router: NextRouter,
  account: Account
) {
  const { returnUrl } = Params.parse(router.query);
  const defaultRedirect = "/";

  const needsOnboarding = computeNeedsOnboarding(account);
  const isOdinUrl = returnUrl?.startsWith("/");
  const isInvite = returnUrl?.includes("invite");

  if (isInvite && isOdinUrl) {
    return await router.replace(returnUrl || defaultRedirect);
  }

  if (!needsOnboarding && isOdinUrl) {
    return await router.replace(returnUrl || defaultRedirect);
  }

  return await router.replace({
    pathname: needsOnboarding ? "/onboarding" : defaultRedirect,
    query: router.query,
  });
}
