import {
  accountsGetSelf,
  getAccountsGetSelfQueryKey,
  useAccountsLogin,
  useAuthProviderList,
} from "@internal/rest/generated/queries/accounts";
import { AuthProvider } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { init } from "services/feature-flags";
import { handleLoginRedirect } from "utility/account";
import { handleResourceEffect } from "utility/async";
import { z } from "zod";

const GOOGLE_PROVIDER_NAME = "google";

export const PasswordCredentials = z.object({
  email: z.string().email("Invalid email, please check it and try again"),
  password: z.string().min(1, "Please type your password"),
});

export type PasswordCredentialsType = z.infer<typeof PasswordCredentials>;

export function usePasswordForm() {
  const router = useRouter();
  const [isSubmitting, setSubmitting] = useState(false);

  const [googleProvider, setGoogleProvider] = useState<
    AuthProvider | undefined
  >(undefined);

  const { mutateAsync: login } = useAccountsLogin();

  const queryClient = useQueryClient();

  const providerResult = useAuthProviderList({
    redirect_url: (router.query["returnUrl"] as string) ?? "/",
  });

  useEffect(() => {
    const g = providerResult.data?.providers.find(
      (p) => p.provider === GOOGLE_PROVIDER_NAME
    );
    setGoogleProvider(g);
  }, [providerResult.data]);

  async function onSubmit(values: PasswordCredentialsType) {
    setSubmitting(true);

    await handleResourceEffect({
      action: async () => {
        await login({
          data: {
            email: values.email,
            password: values.password,
          },
        });

        const account = await accountsGetSelf();

        await init(account);

        queryClient.setQueryData(getAccountsGetSelfQueryKey(), account);

        await handleLoginRedirect(router, account);
      },
      error: {
        title: "Failed login",
      },
      cleanup() {
        setSubmitting(false);
      },
    });
  }

  return {
    ready: router.isReady,
    onSubmit,
    isSubmitting: isSubmitting,
    googleProvider,
  };
}
