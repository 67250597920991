import { Flex, HStack } from "@@panda/jsx";
import { Link } from "components/atoms/Link";
import { Typography } from "components/atoms/Typography";

export function LoginSummary() {
  return (
    <Flex direction="column" align="center" mt="8" width="full">
      <HStack mt={2} w="full" justifyContent="center">
        <Typography variant="body">
          <Typography variant="body" as="span" display="inline-block" mr="2">
            Don’t have an account?
          </Typography>
          <Link href="/signup">Sign up here</Link>
        </Typography>
      </HStack>
    </Flex>
  );
}
