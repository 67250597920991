import { Typography } from "components/atoms/Typography";
import { UnauthorizedLayout } from "components/templates/Layout/UnauthorizedLayout/UnauthorizedLayout";

import { LoginSummary } from "./components/LoginSummary";
import { PasswordForm } from "./components/PasswordForm/PasswordForm";

export function LoginScreen() {
  return (
    <UnauthorizedLayout.Content>
      <Typography variant="heading.2" mb="4" textAlign="center">
        Log in to your account
      </Typography>

      <PasswordForm />
      <LoginSummary />
    </UnauthorizedLayout.Content>
  );
}
