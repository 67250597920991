import { CSSProperties, Children, ReactElement, cloneElement } from "react";

interface PropsForComponent {
  size?: number | string;
  title?: string;
  style?: CSSProperties;
  className?: string;
  ariaHidden?: boolean;
}

export const createComponentFromSvg = (svg: ReactElement) => {
  const component = ({
    title,
    size,
    style,
    className,
    ariaHidden,
  }: PropsForComponent) => {
    const arrayOfChildren = Children.toArray(svg.props.children);
    if (title) {
      arrayOfChildren.unshift(<title>{title}</title>);
    }

    return cloneElement(
      svg,
      {
        height: size ?? svg.props?.height,
        width: size ?? svg.props?.width,
        style,
        className,
        "aria-hidden": !!ariaHidden,
        role: "img",
      },
      ...arrayOfChildren
    );
  };

  return component;
};
